var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "ics-dialog-inner",
        {
          staticClass: "form-dialog",
          attrs: {
            loading: _vm.loadingSubmit.submit,
            visible: _vm.dialog.visible,
            title: _vm.dialog.title,
            "submit-title": "提交",
            "cancel-title": "取消",
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
            submit: _vm.submitForms,
            close: _vm.close
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "", "show-header": true } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "btnCode",
                            label: "审批结果",
                            rules: {
                              required: true,
                              message: "请选择审批结果",
                              trigger: "change"
                            }
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.btnCodeChange },
                              model: {
                                value: _vm.appForm.btnCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "btnCode", $$v)
                                },
                                expression: "appForm.btnCode"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "pass" } }, [
                                _vm._v(" 通过 ")
                              ]),
                              _c("el-radio", { attrs: { label: "back" } }, [
                                _vm._v(" 驳回 ")
                              ]),
                              _c("el-radio", { attrs: { label: "refusal" } }, [
                                _vm._v(" 拒绝 ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "opinion",
                            label: "审批意见",
                            rules: {
                              required: true,
                              message: "请输入审批意见",
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入审批意见(限300字)",
                              maxlength: 300,
                              type: "textarea"
                            },
                            model: {
                              value: _vm.appForm.opinion,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "opinion", $$v)
                              },
                              expression: "appForm.opinion"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }