<template>
  <el-main>
    <ics-dialog-inner :loading="loadingSubmit.submit" :visible.sync="dialog.visible" :title="dialog.title" submit-title="提交" cancel-title="取消" class="form-dialog" width="40%" @submit="submitForms" @close="close">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="120px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="" :show-header="true">
          <el-col :span="24">
            <el-form-item prop="btnCode" label="审批结果" :rules="{ required: true, message: '请选择审批结果', trigger: 'change' }">
              <el-radio-group v-model="appForm.btnCode" @change="btnCodeChange">
                <el-radio label="pass">
                  通过
                </el-radio>
                <el-radio label="back">
                  驳回
                </el-radio>
                <el-radio label="refusal">
                  拒绝
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="opinion" label="审批意见" :rules="{ required: true, message: '请输入审批意见', trigger: 'blur' }">
              <el-input v-model="appForm.opinion" placeholder="请输入审批意见(限300字)" :maxlength="300" type="textarea" />
            </el-form-item>
          </el-col>
        </ics-page-inner>
      </el-form>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsAuditInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    dialog: {
      type: Object,
      default() {
        return {}
      }
    },
    loadingSubmit: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data () {
    return {
      appForm: {
        btnCode: '',
        btnName: '',
        btnStatus: '',
        taskData: {
          flowState: '',
          flowName: '',
        },
        opinion: ''
      },
      rules: {}
    }
  },
  created () {},
  methods: {
    btnCodeChange (value) {
      switch (value) {
        case 'pass':
          this.appForm.btnName = '通过'
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.appForm.btnStatus = '已审核'
          break
        case 'back':
          this.appForm.btnName = '驳回'
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.appForm.btnStatus = '已驳回'
          break
        case 'refusal':
          this.appForm.btnName = '拒绝'
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.appForm.btnStatus = '已拒绝'
          break
        default:
          this.appForm.btnName = ''
          this.appForm.btnStatus = ''
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
      }
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.$emit('submit', this.appForm)
      })
    },
    close () {
      this.appForm.btnCode = ''
      this.appForm.btnName = ''
      this.appForm.btnStatus = ''
      this.appForm.taskData.flowState = ''
      this.appForm.taskData.flowName = ''
      this.appForm.opinion = ''
      this.appForm.clearValidate()
    }
  }
}
</script>

<style scoped>

</style>
